/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onClientEntry = () => {
  // jQuery Tooltips
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  $(document).tooltip({
    placement: 'auto bottom',
    selector: '[title]',
    trigger: 'hover',
    container: 'body',
  })
}
